import gql from "graphql-tag";

/* App.js */
export const CURRENTUSER = gql`
  {
    myprofile {
      id
      first_name
      last_name
      email
      role
      image
      caudex_user_id
      caudex_gp_id
      caudex_lp_id
      viewing_as
      viewing_caudex_entity_id
      primary_entity_name
      caudex_entity_id
      is_caudex_admin
      google_id
      outlook_id
      roles {
        caudex_entity_id
        employee_role
        entity_name
      }
      investments {
        investment_name
        caudex_investment_id
        properties {
          property_name
          caudex_property_id
          caudex_investment_id
        }
      }
    }
  }
`;

export const PROPERTIES_QUERY = gql`
  mutation ($investment: String, $property: String) {
    getProperties(investment: $investment, property: $property) {
      id
      caudex_property_id
      entity_name
      property_name
      color
      city
      state
      image
      investment_amount
      property_type
      LATITUDE
      LONGITUDE
      debt_total
    }
  }
`;

export const GET_CONTACT = gql`
  mutation ($contact: String!) {
    getContact(contact: $contact) {
      json
    }
  }
`;

export const PAY_CAPIAL_CALL = gql`
  mutation ($capital_call_id: String!) {
    payCapitalCall(capital_call_id: $capital_call_id) {
      json
    }
  }
`;

export const GET_TASKS = gql`
  mutation ($contact: String) {
    getTasks(contact: $contact) {
      json
    }
  }
`;

export const SET_DEFAULT_BANK_ACCOUNT = gql`
  mutation ($bank_account_id: String!, $investment: String) {
    setDefaultBankAccount(
      bank_account_id: $bank_account_id
      investment: $investment
    ) {
      success
    }
  }
`;

export const GET_ACTIVITY = gql`
  mutation ($contact: String) {
    getActivities(contact: $contact) {
      json
    }
  }
`;

export const COMPLETE_TASK = gql`
  mutation ($task: Int) {
    completeTask(task: $task) {
      success
    }
  }
`;

export const CHECK_IF_INVESTOR = gql`
  mutation ($contact: String!) {
    checkIfInvestor(contact: $contact) {
      success
    }
  }
`;

export const GET_CONTACT_TASKS = gql`
  mutation ($contact: String!) {
    getContactTasks(contact: $contact) {
      json
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation ($contact: Int!) {
    deleteContact(contact: $contact) {
      success
      redirect
    }
  }
`;

export const DELETE_TASK = gql`
  mutation ($task: Int!) {
    deleteTask(task: $task) {
      success
      redirect
    }
  }
`;

export const GET_GRAPH_PUBLIC_DATA = gql`
  mutation (
    $table: String!
    $property: String
    $entity: String
    $investment: String
    $type: String
    $user: String
  ) {
    getGraphPublicData(
      table: $table
      property: $property
      entity: $entity
      investment: $investment
      type: $type
      user: $user
    ) {
      json
    }
  }
`;

export const VIEWAS_MUTATION = gql`
  mutation ($id: Int!) {
    switchViewAs(id: $id) {
      viewing_as
    }
  }
`;

export const VIEWASENTITY_MUTATION = gql`
  mutation ($caudex_entity_id: String!) {
    switchViewAsEntity(caudex_entity_id: $caudex_entity_id) {
      viewing_caudex_entity_id
    }
  }
`;

export const GET_ALL_INVESTORS = gql`
  mutation (
    $investment: String
    $entity: String
    $document: String
    $report: String
  ) {
    getAllInvestors(
      investment: $investment
      entity: $entity
      document: $document
      report: $report
    ) {
      json
    }
  }
`;

export const GET_CONTACTS = gql`
  mutation ($tags: String, $types: String) {
    getContacts(tags: $tags, types: $types) {
      json
    }
  }
`;

export const SAVE_CONTACT_INFO = gql`
  mutation ($type: String, $contactID: String, $value: Int, $function: String) {
    saveContactInfo(
      type: $type
      contactID: $contactID
      value: $value
      function: $function
    ) {
      json
    }
  }
`;

export const SAVE_CONTACT_ATTRIBUTE = gql`
  mutation ($type: String, $value: String, $function: String) {
    saveContactAttribute(type: $type, value: $value, function: $function) {
      json
    }
  }
`;

/* EDITABLE TABLE */
export const GET_DATABASE_TABLE_DATA = gql`
  mutation (
    $table: String!
    $type: String!
    $q: String
    $data: String
    $child: String
    $entity: String
    $investment: String
    $property: String
    $management: Boolean
    $custom_table_name: String
    $caudex_user_id: String
  ) {
    getDatabaseTableData(
      table: $table
      type: $type
      q: $q
      data: $data
      child: $child
      entity: $entity
      investment: $investment
      property: $property
      management: $management
      custom_table_name: $custom_table_name
      caudex_user_id: $caudex_user_id
    ) {
      json
      required_fields
    }
  }
`;

/* SIDEBAR CONTENT */
export const GET_SIDEBAR_DATA = gql`
  mutation (
    $sidebar: String!
    $investment: String
    $property: String
    $management: Boolean
    $caudex_user_id: String
  ) {
    getSideBarData(
      sidebar: $sidebar
      investment: $investment
      property: $property
      management: $management
      caudex_user_id: $caudex_user_id
    ) {
      json
    }
  }
`;

export const REMOVE_TABLE_DATA = gql`
  mutation ($table: String!, $data: Int) {
    removeTableData(table: $table, data: $data) {
      json
    }
  }
`;

export const SAVE_DATABASE_TABLE_DATA = gql`
  mutation ($table: String!, $data: String) {
    saveDatabaseTableData(table: $table, data: $data) {
      json
    }
  }
`;

/* DOCUMENTS VIEW */
export const NEW_FILE_QUERY = gql`
  mutation ($meta: String, $file: Upload) {
    newFile(meta: $meta, file: $file) {
      success
    }
  }
`;

export const UPLOAD_QUERY = gql`
  mutation ($file: Upload!, $type: String) {
    newUpload(file: $file, type: $type) {
      id
    }
  }
`;

export const FOLDERS_QUERY = gql`
  mutation ($id: String) {
    getFolders(id: $id) {
      id
      folder_name
      caudex_folder_id
      parent
      documents {
        id
        url
        image
        name
        type
        caudex_document_id
        owner_id
        property_name
        investment_name
        document_type
        owner
        uploaded_on
      }

      folders {
        id
        folder_name
        caudex_folder_id
      }
    }
  }
`;

export const DELETE_FILE_QUERY = gql`
  mutation ($docID: String, $reportID: String) {
    deleteDocument(docID: $docID, reportID: $reportID) {
      success
    }
  }
`;

export const DYNAMIC_QUERY = gql`
  mutation ($q: String, $type: String) {
    getSearchOptions(q: $q, type: $type) {
      json
    }
  }
`;

/* Equity Table */
export const GET_GRAPH_DATA = gql`
  mutation (
    $user: String
    $table: String!
    $property: String
    $type: String
    $investment: String
    $dbname: String
    $investor: String
    $data: String
  ) {
    getGraphData(
      user: $user
      table: $table
      property: $property
      type: $type
      investment: $investment
      dbname: $dbname
      investor: $investor
      data: $data
    ) {
      json
    }
  }
`;

export const GET_FINANCIAL_COLUMN_DATA = gql`
  mutation (
    $table: String!
    $property: String
    $type: String
    $investment: String
    $dbname: String
    $investor: String
    $data: String
  ) {
    getGraphData(
      table: $table
      property: $property
      type: $type
      investment: $investment
      dbname: $dbname
      investor: $investor
      data: $data
    ) {
      json
    }
  }
`;

export const SAVE_FINANCIAL_ROW = gql`
  mutation ($data: String!) {
    savePropertyFinancialRow(data: $data) {
      json
    }
  }
`;

export const GLOBAL_SEARCH_QUERY = gql`
  mutation ($q: String!, $searchName: String) {
    globalSearch(q: $q, searchName: $searchName) {
      json
    }
  }
`;

export const SAVE_FINANCIAL_DATA = gql`
  mutation ($data: String, $investment: String) {
    saveFinancialData(data: $data, investment: $investment) {
      json
    }
  }
`;

export const SAVE_DATE_COLUMN = gql`
  mutation ($propertyData: String, $table: String, $investmentData: String) {
    saveFinancialColumnData(
      propertyData: $propertyData
      investmentData: $investmentData
      table: $table
    ) {
      json
    }
  }
`;

export const DELETE_DATE_COLUMN = gql`
  mutation ($data: String, $table: String) {
    deleteFinancialColumn(data: $data, table: $table) {
      json
    }
  }
`;

export const customStyles = {
  text: {
    primary: "#bdd1f8",
    secondary: "#bdd1f8",
  },
  background: {
    default: "transparent",
  },
  context: {
    background: "transparent",
    text: "#FFFFFF",
  },
  divider: {
    default: "transparent",
  },
  action: {
    button: "#bdd1f8",
    hover: "#bdd1f8",
    disabled: "transparent",
  },
  striped: {
    default: "transparent",
    text: "#bdd1f8",
  },
  rows: {
    style: {
      minHeight: "35px",
      fontSize: "16px",
      fontWeight: "400",
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      background: "#1e3149",
      minHeight: "34px",
      fontWeight: "600",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
    },
  },
};

export const GET_FORM_DATA = gql`
  mutation (
    $table: String!
    $type: String
    $investment: String
    $caudex_user_id: String
    $id: Int
    $custom_table_name: String
  ) {
    getDatabaseTableColumns(
      table: $table
      type: $type
      investment: $investment
      caudex_user_id: $caudex_user_id
      id: $id
      custom_table_name: $custom_table_name
    ) {
      json
    }
  }
`;

export const NEW_TABLE_ROW = gql`
  mutation ($table: String!, $data: String!) {
    saveDatabaseTableColumns(table: $table, data: $data) {
      json
    }
  }
`;

export const INVITE_INVESTORS = gql`
  mutation ($table: String!, $data: String!) {
    inviteInvestors(table: $table, data: $data) {
      json
    }
  }
`;

export const GET_DOCUMENTS = gql`
  mutation ($tags: String, $types: String) {
    getDocuments(tags: $tags, types: $types) {
      json
    }
  }
`;
export const SAVE_DRAGGED_DOCUMENTS = gql`
  mutation ($name: String, $url: String) {
    saveDraggedDocument(name: $name, url: $url) {
      json
    }
  }
`;

export const SAVE_DOCUMENT_INFO = gql`
  mutation (
    $type: String
    $documentID: String
    $value: Int
    $function: String
  ) {
    saveDocumentInfo(
      type: $type
      documentID: $documentID
      value: $value
      function: $function
    ) {
      json
    }
  }
`;

export const SAVE_DOCUMENT_ATTRIBUTE = gql`
  mutation ($type: String, $value: String, $function: String) {
    saveDocumentAttribute(type: $type, value: $value, function: $function) {
      json
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation ($document: Int!) {
    deleteDocument(document: $document) {
      success
      redirect
    }
  }
`;
export const GET_DOCUMENT = gql`
  mutation ($document: String!) {
    getDocument(document: $document) {
      json
    }
  }
`;
